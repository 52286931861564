import axios from 'axios';
import https from 'https';

const httpAgent = new https.Agent({
  rejectUnauthorized: process.env.NODE_ENV !== 'development',
});

axios.defaults.httpAgent = httpAgent;

const fetchAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ROOT,
  withCredentials: true,
});

export const neshan = axios.create({
  headers: {
    'Api-key': 'service.PbiLUcgqwhFqoPY1p7NIhoupxDs82gCuLacruB5u',
  },
  baseURL: 'https://api.neshan.org',
});

export default fetchAPI;
