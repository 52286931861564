import '../styles/globals.sass';
import 'nprogress/nprogress.css';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { toast, ToastBar, Toaster } from 'react-hot-toast';

import Providers from '../contexts/Providers';

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());

    return () => {
      Router.events.off('routeChangeStart', () => NProgress.start());
      Router.events.off('routeChangeComplete', () => NProgress.done());
      Router.events.off('routeChangeError', () => NProgress.done());
    };
  }, []);

  return (
    <Providers>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
      </Head>
      <Toaster gutter={8} position="top-center">
        {(t) => (
          <>
            <ToastBar
              toast={t}
              style={{
                ...t.style,
              }}
            >
              {({ icon, message }) => (
                <div className="flex font-medium text-base">
                  {icon}
                  {message}
                  {t.type !== 'loading' && (
                    <button
                      className="self-stretch -my-2.5 border-r border-gray-400 border-opacity-20 text-dark-900 text-opacity-50 px-4 -ml-2.5 mr-3 font-bold"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      بستن
                    </button>
                  )}
                </div>
              )}
            </ToastBar>
          </>
        )}
      </Toaster>
      <Component {...pageProps} />
    </Providers>
  );
}

export default MyApp;
