import React, { ReactNode } from 'react';
import { SWRConfig } from 'swr';

import fetchAPI from '../api';

interface IProps {
  children: ReactNode;
}

const Providers = ({ children }: IProps) => {
  return (
    <SWRConfig
      value={{
        fetcher: (url) => fetchAPI.get(url).then((res) => res.data),
        revalidateOnMount: true,
        shouldRetryOnError: true,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default Providers;
